<template>
  <div
    :class="`custom-tab-messages  bg-ems-gray800 h-[calc(100%)] ${
      isCollapse ? 'w-[100px]' : 'w-full'
    }`"
  >
    <div v-if="isCollapse" class="flex justify-center items-center pt-[20px]">
      <img
        src="@/static/img/icon/nav-expand.png"
        class="cursor-pointer"
        @click="isCollapse = false"
      />
    </div>
    <div v-if="!isCollapse" class="flex justify-between items-center p-[20px]">
      <div
        v-if="isShowMember === TAB_WALKIE.VIEW_MEMBERS"
        class="flex items-center space-x-[10px]"
      >
        <img
          alt="expand"
          src="@/static/img/icon/expand.svg"
          class="cursor-pointer"
          @click="handleCloseViewMember"
        />
        <p class="text-ems-gray200 text-lg uppercase">
          {{ t('coordinator.view_all_members') }}
        </p>
      </div>
      <div
        v-else-if="isShowMember === TAB_WALKIE.SEND_MESSAGE"
        class="flex items-center space-x-[10px]"
      >
        <img
          alt="expand"
          src="@/static/img/icon/expand.svg"
          class="cursor-pointer"
          @click="handleCloseViewMember"
        />
        <p class="text-ems-gray200 text-lg uppercase">
          {{ t('coordinator.share_message') }}
        </p>
      </div>
      <p v-else class="text-ems-gray200 text-lg uppercase">
        {{ t('coordinator.walkie_messages') }}
      </p>
      <img
        src="@/static/img/icon/nav-collapse.png"
        class="cursor-pointer"
        @click="isCollapse = true"
      />
    </div>
    <div v-if="!isCollapse">
      <div v-if="isShowMember === TAB_WALKIE.VIEW_MEMBERS">
        <TopChat />
        <div class="h-[0.5px] w-full bg-ems-gray700"></div>
        <ListMember />
      </div>
      <div
        v-else-if="isShowMember === TAB_WALKIE.SEND_MESSAGE"
        class="px-[20px]"
      >
        <ListSend />
      </div>
      <div
        v-else-if="
          isShowMember === TAB_WALKIE.CHAT || isShowMember === TAB_WALKIE.WALKIE
        "
      >
        <a-tabs
          v-model:activeKey="activeTab"
          centered
          @change="handleChangeKey"
        >
          <a-tab-pane v-for="item in tabs" :key="item.key">
            <template #tab>
              <span>{{ item.tabName }} </span>
            </template>
          </a-tab-pane>
        </a-tabs>
        <Suspense>
          <template #default>
            <component :is="component" />
          </template>
          <template #fallback>
            <div class="spin-custom">
              <cds-loading />
            </div>
          </template>
        </Suspense>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, defineAsyncComponent, watch } from 'vue';
import { useStore } from 'vuex';
import TopChat from './tab/components/top-chat.vue';
import ListMember from './tab/components/list-member.vue';
import ListSend from './tab/components/list-send.vue';
import { TAB_WALKIE } from '@/util/common-constant.js';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const { state, dispatch } = useStore();
const isShowMember = computed(() => state.coordinator.isShowMember || '');
const itemValue = computed(() => state.coordinator.itemValue || '');
const router = useRouter();
const route = useRoute();
const isCollapse = ref(false);
const activeTab = ref(TAB_WALKIE.WALKIE);
const TabWalkieTalkie = defineAsyncComponent(() =>
  import('./tab/tab-walkie-talkie.vue')
);
const TabMessage = defineAsyncComponent(() => import('./tab/tab-message.vue'));
const component = ref('');
const tabs = [
  {
    key: TAB_WALKIE.WALKIE,
    tabName: t('coordinator.walkie'),
    component: TabWalkieTalkie,
  },
  {
    key: TAB_WALKIE.CHAT,
    tabName: t('coordinator.messages'),
    component: TabMessage,
  },
];
const handleCloseViewMember = () => {
  dispatch('coordinator/viewMember', route.query.tab_walkie);
};
watch(
  () => route.query,
  () => {
    if (!route.query.tab_walkie) {
      activeTab.value = TAB_WALKIE.WALKIE;
    } else {
      activeTab.value = route.query.tab_walkie;
    }
    dispatch('coordinator/viewMember', route.query.tab_walkie);
    tabs.forEach((item) => {
      if (item.key === activeTab.value) {
        component.value = item.component;
      }
    });
  },
  {
    immediate: true,
    deep: true,
  }
);
const handleChangeKey = (value) => {
  let query = {
    id: route.query.id,
    type: route.query.type,
    tab_walkie: value,
    tab_list: route.query.tab_list,
  };
  dispatch('coordinator/handleSetParams', query);
};
</script>
<style lang="scss">
.custom-tab-messages {
  transition: width 0.5s ease;
}
.custom-tab-messages .ant-tabs-nav-list {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #434343;
  margin-left: 20px;
  margin-right: 20px;
}
.custom-tab-messages .ant-tabs-tab {
  width: 200px;
  display: flex;
  justify-content: center;
  height: 100;
}
</style>
